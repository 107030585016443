.invalid {
  font-size: 11px;
  font-style: normal !important;
  position: absolute;
  left: 0;
  color: #fff !important;
  font-size: 11px;
  line-height: 1;
  bottom: calc(100% + 4px);
  background: #ed756b;
  padding: 0.3rem 0.5rem;
  z-index: 1;
  border-radius: 3px;
  white-space: nowrap;
  left: auto;
  right: 0;
}

.invalid::before {
  left: auto;
  right: 10px;
  border-right-color: #ed756b;
  border-left-color: transparent;
  bottom: -4px;
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-left: 6px solid #ed756b;
  border-right: 6px solid transparent;

  border-bottom: 6px solid transparent;
}

.error-border {
  border-color: #e85347 !important;
}

/***************************************** 
LOADING SPINNNER
******************************************/

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: grid;
  place-content: center;
  min-height: 300px;
}

.page-overlay-shade {
  background-color: rgba(255, 255, 255, 0.725);
}

.page-overlay-bg {
  background-color: white;
}
.page-overlay.bg {
  background-color: #f5f6fa;
}
.page-loader {
  position: relative;
  width: 48px;
  height: 48px;
  background: #787878;
  transform: rotateX(65deg) rotate(45deg);
  /* // remove bellows command for perspective change
  //transform: perspective(200px) rotateX(65deg) rotate(45deg); */
  color: rgb(0, 0, 0);
  animation: layers1 1s linear infinite alternate;
}
.page-loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  animation: layerTr 1s linear infinite alternate;
}
.page-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: grid;
  place-content: center;
  background-color: white;
}
@keyframes layers1 {
  0% {
    box-shadow: 0px 0px 0 0px;
  }
  90%,
  100% {
    box-shadow: 20px 20px 0 -4px;
  }
}
@keyframes layerTr {
  0% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    transform: translate(-25px, -25px) scale(1);
  }
}

/***************************************** 
PRODUCT
******************************************/
.cut-price {
  text-decoration: line-through;
  color: #c7c7c7;
  font-size: 70%;
  font-weight: normal;
}

/***************************************** 
INPUTS
******************************************/
.form-control-wrap.phone {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 10px;
}

.form-control-wrap.phone > div:first-child {
  text-align: center;
  background-color: #f7f7f7;
}

/***************************************** 
UTILITIES
******************************************/
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.min-h-250 {
  min-height: 250px;
}
.w-10px {
  width: 10px;
}
@media print {
  .print-none {
    display: none;
  }
}
