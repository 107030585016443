.mainContainer {
  display: grid;
  place-content: center;
}
.imageContainer {
  position: relative;
  margin: auto;
}

.imageContainer label {
  background: #f7f7f7;
  margin-bottom: 0;
  display: grid;
  place-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.imageContainerCircle {
  border-radius: 100%;
}
.imageContainerRectangle {
  border-radius: 5px;
}

.imageContainer img {
  object-position: center;
  object-fit: cover;
}
.mainContainer > p {
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}

.plusIcon {
  font-size: 2rem;
  color: #e2e2e2;
}

.removebtn {
  width: min-content;
  position: absolute !important;

  bottom: 0;
  right: 0;
}

.errorImage label {
  border: solid 1px red;
}

.invalidMessage {
  text-align: center;
  /* font-style: italic; */
  font-size: 11px;
  color: #e85347;
}
