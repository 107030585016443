.dropzoneContainer {
  display: grid;
  gap: 1.3rem;
  align-items: center;
  grid-template-columns: repeat(auto-fit, 150px);
  position: relative;
  /* grid-template-columns: repeat(auto-fit, 150px); */
}

.dropzoneError {
  border-color: red;
}
.dropzoneContainer > div {
  height: 150px;
  background: #f7f7f7;
  display: grid;
  place-content: center;
  position: relative;
}
.dropzoneContainer > div > img {
  object-fit: cover;
}
.dropzoneContainer button {
  width: min-content;
  display: grid;
  place-content: center;
}
.dropzoneContainer > div:not(:last-child):hover::after {
  font-weight: 900;
  content: "Remove";
  color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f0bcbc76;
  display: grid;
  place-content: center;
  cursor: pointer;
}
